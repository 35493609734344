import React from "react";
import AppWrapperNewDesign from "@core/components/AppWrapper/components/AppWrapperNewDesign";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";

const AppWrapper = ({children}) => {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // temporary solution until we remove/refactor class components that are complex/dangerous to refactor to a functional component
  const childrenWithProps = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      location,
      match,
      history
    });
  });

  return <AppWrapperNewDesign children={childrenWithProps} />;
};

export default AppWrapper;
