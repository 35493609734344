import React from "react";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {Grid, Button, Typography} from "@mui/material";
import UserList from "./components/UserList";

const UserOverview = () => {
  const match = useRouteMatch();
  const location = useLocation();

  return (
    <div className="content padded-container">
      <Grid container alignItems="center" spacing={3}>
        <Grid
          container
          item
          spacing={3}
          sx={{justifyContent: "space-between"}}
        >
          <Grid item>
            <Typography variant="h4" fontSize="1.8rem">
              Users
            </Typography>
          </Grid>
          <Grid item sx={{display: "flex", gap: "16px"}}>
            <Button
              component={Link}
              to="/admin/users/new"
              variant="contained"
              color="primary"
            >
              Create user
            </Button>
            <Button
              component={Link}
              to="/admin/users/invite"
              variant="contained"
              color="primary"
            >
              Invite User
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <UserList match={match} location={location} />
        </Grid>
      </Grid>
    </div>
  );
};

export default UserOverview;
