/**
 * Parses objectId from MongoDB into Date
 * @param {objectId} objectId 
 * @returns Date
 */
export const getDateFromObjectId = (objectId) => {
  const stamp = parseInt(objectId.substring(0, 8), 16);
  const date = new Date(stamp * 1000);

  return date;
};
