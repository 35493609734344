import Items from "@core/components/Items";
import OrderNumber from "@core/components/OrderNumber";
import ProjectId from "@core/components/ProjectId";
import Tags from "@core/components/Tags";
import {WITNESS_STATUSES} from "@core/constants/witnessStatuses";
import {Button} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export const getColumns = (normsByMaterial) => [
  {
    dataIndex: "action",
    permanent: true,
    title: "Action",
    render: (certificate) => (
      <Button
        component={Link}
        to={`/certificates/${certificate._id}`}
      >
        View
      </Button>
    )
  }, {
    isSorted: "true",
    dataIndex: "heat",
    title: "Heat",
  }, {
    isSorted: "true",
    dataIndex: "properties.lotId",
    title: "Lot ID",
  }, {
    isSorted: "true",
    dataIndex: "_id",
    title: "Certificate ID"
  }, {
    isSorted: "true",
    dataIndex: "orderNumber",
    title: "PO Number",
    render: (certificate) => (
      <OrderNumber order={certificate.order} />
    )
  }, {
    isSorted: "true",
    dataIndex: "internalWorkOrder",
    title: "Work Order"
  }, {
    isSorted: "true",
    dataIndex: "internalItemNumber",
    title: "Work Order item"
  }, {
    isSorted: "true",
    dataIndex: "lineItem",
    title: "PO item"
  }, {
    isSorted: "true",
    dataIndex: "projectId",
    title: "Project ID",
    render: (certificate) => {
      if (!certificate.projectId) return null;

      return (
        <ProjectId
          certificate={certificate}
        />
      );
    }
  }, {
    isSorted: "true",
    dataIndex: "properties.productType",
    title: "Product Type",
    render: (certificate) => certificate.properties.productType?.name
  }, {
    isSorted: "true",
    dataIndex: "properties.norm",
    title: "Material Specification",
  }, {
    isSorted: "true",
    dataIndex: "properties.grade",
    title: "Grade/UNS",
    render: (certificate) => {
      const config = normsByMaterial[certificate.properties.grade] || {};
      const deliveryConditions = config.DeliveryConditions || {};
      const deliveryCondition = certificate.deliveryCondition && deliveryConditions[certificate.deliveryCondition];

      return (
        <>
          {certificate.properties.grade}
          {deliveryCondition}
        </>
      );
    },
  }, {
    dataIndex: "witnesses",
    title: "Witnesses",
    render: (certificate) => {
      const isWitnessed = certificate.tests?.some((test) => test?.witnesses?.length);

      return isWitnessed ? "Yes" : null;
    }
  }, {
    dataIndex: "inspectionResult",
    title: "Inspection result",
    render: (certificate) => {
      if (!certificate.tests?.length) return "-";
    
      const isWitnessed = certificate.tests?.some((test) => test.witnesses?.length);
      const fullyInspected = certificate.tests?.every((test) => !test.witnesses?.length || test.witnesses.find((witness) => witness.status));

      if (!isWitnessed || !fullyInspected) return "-";
    
      const inspectionPassed = certificate.tests?.every((test) => {
        return test.witnesses.find((witness) => witness.status === WITNESS_STATUSES.ACCEPTED);
      });
    
      return inspectionPassed ? "Inspection passed" : "Inspection not passed";
    }
  }, {
    dataIndex: "items",
    title: "Items",
    render: (certificate) => <Items certificate={certificate} />
  }, {
    dataIndex: "tags",
    title: "Tags",
    render: (certificate) => {
      if(!certificate.tags || !certificate.tags.length) return "-";
      
      return (
        <Tags
          tags={certificate.tags}
        />
      );
    }
  }
];
