import React from "react";
import {groupBy, prop} from "ramda";
import {MuiAutocomplete} from "@core/components/Form";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";

const removeSpecialCharacters = (string) =>
  string?.replace(/[^a-zA-Z0-9]/g, "");

export const formatOptions = (data) => {
  const groupedByNorm = groupBy(prop("Norm"), data);

  return Object.entries(groupedByNorm).map(([norm, value]) => ({
    label: norm,
    value: {value, norm}
  }));
};

const NormAutocomplete = ({
  onChange,
  testType,
  label,
  name,
  required = true,
  ...rest
}) => {
  const onLoad = (options, value) => {
    const valueWithoutSpecialCharacters = removeSpecialCharacters(value);
    const field = options?.find((o) => {
      const optionWithoutSpecialCharacters = removeSpecialCharacters(o.label);

      return optionWithoutSpecialCharacters.includes(
        valueWithoutSpecialCharacters
      );
    });

    if (!field || !value) return options;

    onChange(field.value);
  };

  return (
    <MuiAutocomplete
      inputProps={{
        label,
        name,
        required
      }}
      url={ROUTES.TEST_NORM[ACTIONS.ALL_BY_QUERY](testType)}
      onChange={onChange}
      formatOptions={formatOptions}
      onLoad={onLoad}
      {...rest}
    />
  );
};

export default NormAutocomplete;
