import React from "react";
import Actions from "./components/Actions";
import {getColumns} from "@core/columns/campaign";

export const columns = (currentPage, module, setCampaignToDeleteId) => {
  const defaultColumns = getColumns(currentPage, module.name);

  return [
    ...defaultColumns,
    {
      dataIndex: "internalWorkOrder",
      title: "Internal work order",
      render: (campaign) => campaign.internalWorkOrder || "-",
    },
    {
      dataIndex: "action",
      permanent: true,
      title: "Action",
      render: (campaign) => (
        <Actions
          campaign={campaign}
          onDelete={() => setCampaignToDeleteId(campaign._id)}
        />
      ),
    },
  ];
};
