import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Box, Collapse} from "@mui/material";
import MenuList from "../MenuList";
import Account from "../Footer/components/Account";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import Settings from "../Settings";

const Content = ({expanded, onMouseOver, onMouseLeave}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box
      onMouseOver={onMouseOver}
      onMouseLeave={() => {
        setIsExpanded(false);
        onMouseLeave();
      }}
      sx={{
        padding: "20px",
        backgroundColor: (theme) => theme.palette.common.white,
        overflowY: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        fontWeight: (theme) => theme.typography.fontWeightSemibold,
        fontSize: "0.82rem",
        width: expanded ? "230px" : "fit-content",
        borderRight: (theme) => `1px solid ${theme.palette.secondary.grey}`,
      }}
    >
      <MenuList expanded={expanded} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
        onMouseOver={() => setIsExpanded(true)}
      >
        {expanded && (
          <Collapse in={isExpanded}>
            <Settings />
          </Collapse>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Account expanded={expanded} />
          </Box>

          {expanded && (isExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />)}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(Content);
