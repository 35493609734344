import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {Box} from "@mui/material";
import useStores from "../../../../../../../useStores";
import ErrorBoundary from "../../../ErrorBoundary";
import {useLocation} from "react-router-dom";
import Menu from "../Menu";
import {COLLAPSED_MENU_WIDTH, EXPANDED_MENU_WIDTH} from "../../constants";

const Content = ({children}) => {
  const {MenuStore} = useStores();

  const [expanded, setExpanded] = useState(!MenuStore.collapsed);

  const location = useLocation();

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "fixed", // this is for useWindowVirtualizer, remove it and use plain flexbox when stop using useWindowVirtualizer
          zIndex: 999,
        }}
      >
        <Menu expanded={expanded} setExpanded={setExpanded} />
      </Box>
      <Box
        sx={{
          marginLeft: `${expanded ? EXPANDED_MENU_WIDTH : COLLAPSED_MENU_WIDTH}px`,
        }}
      >
        <ErrorBoundary key={location.pathname}>{children}</ErrorBoundary>
      </Box>
    </Box>
  );
};

export default observer(Content);
