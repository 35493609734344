import {equals, is, pathOr} from "ramda";

export function getLabel(value, options) {
  const selectedOption = options?.find((o) => {
    return equals(o.value, value);
  });
  const defaultLabel = is(String, value) ? value : "";
  const label = pathOr(defaultLabel, ["label"], selectedOption);

  return label;
}

export function defaultFormatOptions(data) {
  return data.map((item) => ({
    label: item.name || item,
    value: item
  }));
}
