import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  Button,
  MenuItem,
} from "@mui/material";
import * as yup from "yup";
import {Formik} from "formik";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import useStores from "../../../useStores";
import modules from "@core/constants/modules";

const initialValues = {
  companyId: "",
  orderNumber: "",
};

const validationSchema = yup.object().shape({
  companyId: yup.string().required("The field is required!"),
  orderNumber: yup.string().required("The field is required!"),
});

const CUSTOMERS_BY_INTERFACE_TYPE = {
  [INTERFACE_TYPE.RECEIVER]: [modules.PRODUCER],
  [INTERFACE_TYPE.SENDER]: [modules.PRODUCER, modules.END_OWNER],
};

const CreateCustomerOrderModal = ({
  open,
  setOpen,
  onTransferCreated,
  interfaceType,
}) => {
  const {TransferStore, UserStore, NotificationStore} = useStores();

  const activeProducers = TransferStore.receivers;

  useEffect(() => {
    TransferStore.getTransfersReceivers(
      CUSTOMERS_BY_INTERFACE_TYPE[interfaceType],
    );
  }, [interfaceType.length]);

  const createOrder = async (values) => {
    const companyId = UserStore.user.data.company._id;

    const data = {
      orderNumber: values.orderNumber,
      sender:
        interfaceType === INTERFACE_TYPE.SENDER ? companyId : values.companyId,
      receiver:
        interfaceType === INTERFACE_TYPE.SENDER ? values.companyId : companyId,
    };

    await TransferStore.createTransfer(data);

    onTransferCreated(TransferStore.transfer._id);
    setOpen(false);
    NotificationStore.showSuccess("Customer order created!");
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Create order</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={createOrder}
        >
          {(props) => (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SelectField
                  name="companyId"
                  label={
                    interfaceType === INTERFACE_TYPE.SENDER ?
                      "Customer" :
                      "Manufacturer"
                  }
                  required
                >
                  {activeProducers.map((producer) => (
                    <MenuItem key={producer._id} value={producer._id}>
                      {producer.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12}>
                <TextField required label="PO Number" name="orderNumber" />
              </Grid>
              <Grid item container justifyContent="flex-end">
                <Button
                  disabled={!props.isValid}
                  onClick={props.handleSubmit}
                  color="primary"
                  variant="contained"
                  autoFocus
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

CreateCustomerOrderModal.defaultProps = {
  onTransferCreated: () => {},
};

export default observer(CreateCustomerOrderModal);
