import React from "react";
import {Box, Grid} from "@mui/material";
import {PAGE_PADDING} from "../../constants";
import Toolbar from "./components/Toolbar";
import Legend from "./components/Legend";
import ScaleHandlers from "./components/ScaleHandlers";
import Settings from "./components/Settings";

const Footer = ({
  container,
  settings,
  setSettings,
  stalksNumbers,
  isFullScreen,
  view,
  onChangeView,
  state,
  stageWidth,
  zoomBy,
  setDefaultStageStatus,
  scale,
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: isFullScreen ? PAGE_PADDING : 0,
        bottom: isFullScreen ? PAGE_PADDING : 0,
        paddingRight: isFullScreen ? `${2 * PAGE_PADDING}px` : 0,
        width: "100%",
      }}
    >
      <Grid container spacing={1} alignItems="flex-end" wrap="nowrap">
        <Grid item>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>
              <Toolbar
                container={container}
                settings={settings}
                setSettings={setSettings}
                stalksNumbers={stalksNumbers}
                isFullScreen={isFullScreen}
                state={state}
              />
            </Grid>
            <Grid item>
              <Settings
                isFullScreen={isFullScreen}
                container={container}
                setSettings={setSettings}
                onChangeView={onChangeView}
                settings={settings}
                view={view}
                state={state}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{flexGrow: 1}}>
          <Legend
            state={state}
            view={view}
            settings={settings}
            stageWidth={stageWidth}
          />
        </Grid>
        <Grid item>
          <ScaleHandlers
            container={container}
            zoomBy={zoomBy}
            isFullScreen={isFullScreen}
            setDefaultStageStatus={setDefaultStageStatus}
            scale={scale}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
