import React from "react";
import {Box, Button} from "@mui/material";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {
  COMPANY_ITEM,
  ACCOUNT_ITEM,
  SETTINGS_ITEM,
} from "../../../../constants";
import useStores from "../../../../../../../../../useStores";
import MenuItem from "../MenuItem";
import roles from "../../../../../../../../constants/roles";
import IconistsIcon from "@core/components/IconistsIcon";
import {ICONISTS_ICONS} from "@core/constants/iconistsIcons";

const Settings = () => {
  const {UserStore} = useStores();
  const role = UserStore.user.data.role;

  const history = useHistory();

  return (
    <Box
      sx={{
        padding: "12px",
        borderRadius: "8px",
        border: "1px solid #AFAFAF",
      }}
    >
      <Box>
        <MenuItem menuItem={ACCOUNT_ITEM} expanded />
        {role != roles.SUPER_ADMIN && (
          <>
            <MenuItem menuItem={COMPANY_ITEM} expanded />
            <MenuItem menuItem={SETTINGS_ITEM} expanded />
          </>
        )}
        <Button
          onClick={() => history.push("/logout")}
          sx={{
            marginTop: "12px",
            minWidth: "40px",
            width: "100%",
            display: "flex",
            gap: "8px",
          }}
        >
          <IconistsIcon
            width="24px"
            icon={ICONISTS_ICONS.logout}
            color="#AFAFAF"
          />
          {"Logout"}
        </Button>
      </Box>
    </Box>
  );
};

export default Settings;
