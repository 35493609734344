import React from "react";
import {withStyles} from "tss-react/mui";
import classNames from "classnames";
import {getAutocompleteOptionLabel} from "@core/helpers";
import {CREATE_NEW_OPTION} from "@core/constants/createNewAutocompleteOption";
import {autocompleteClasses} from "@mui/material/Autocomplete";

const MuiAutocompleteOption = withStyles(
  ({className, classes, option, ...restProps}) => {
    return (
      <li
        {...restProps}
        className={classNames(className, {
          [classes.createNewHighLight]:
            option?.value === CREATE_NEW_OPTION.value,
        })}
      >
        {getAutocompleteOptionLabel(option)}
      </li>
    );
  },
  () => ({
    createNewHighLight: {
      [`&&.${autocompleteClasses.focused}`]: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
  }),
);

export default MuiAutocompleteOption;
