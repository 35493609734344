import React, {useCallback, useMemo, useState} from "react";
import {flatten, groupBy, values, partition} from "ramda";
import {observer} from "mobx-react-lite";
import {RxOpenInNewWindow} from "react-icons/rx";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import TableFooter from "@core/components/TableFooter";
import TestsDrawer from "@core/components/TestsDrawer";
import FilterableSortableHeaderCell from "@core/components/FilterableSortableHeaderCell";
import TableSkeleton from "@core/components/TableSkeleton";
import {TYPES} from "@core/constants/test";
import {ORDERS} from "@core/constants/sort";
import {filterElements, sort, sortStrings} from "@core/helpers";
import {CAMPAIGN_TYPES} from "@core/constants/campaign";
import useStores from "../../../../../../useStores";
import {useParams} from "react-router-dom";

const CompactBaseMaterials = observer((props) => {
  const {CampaignStore} = useStores();

  const {id: campaignId} = useParams();

  const [heat, setHeat] = useState(null);

  const [limit, setLimit] = useState(30);
  const [offset, setOffset] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filter, setFilter] = useState({});

  const productionTransfers = CampaignStore.transfers;

  const campaign = CampaignStore.campaign;

  const isLoaded = campaignId === CampaignStore.campaign._id;

  const groupedProducts = useMemo(
    () =>
      groupBy(
        (product) =>
          product.heat +
          product.productType +
          product.manufacturer +
          product.quarantine +
          product.quarantineReason,
        props.products,
      ),
    [props.products],
  );
  const products = useMemo(
    () =>
      values(groupedProducts).map((chunk) => {
        const stalkNumbers = chunk.reduce((stalkNumbers, product) => {
          if (
            product.stalkNumber &&
            !stalkNumbers.includes(product.stalkNumber)
          ) {
            stalkNumbers.push(product.stalkNumber);
          }

          return stalkNumbers;
        }, []);

        return {
          ...chunk[0],
          quantity: chunk.length,
          stalkNumbers: sortStrings(ORDERS.ASC, stalkNumbers),
        };
      }),
    [groupedProducts],
  );

  const filtered = useMemo(
    () => filterElements(filter, products),
    [filter, products],
  );
  const sorted = useMemo(
    () => sort(order, orderBy, filtered),
    [filtered, order, orderBy, filter],
  );

  const productsPaginated = useMemo(
    () => sorted.slice(offset, offset + limit),
    [offset, limit, sorted],
  );

  const productionTestsFilteredByHeat = useMemo(() => {
    if (!heat) return [];

    return productionTransfers.reduce((tests, transfer) => {
      const transferTests = flatten(
        transfer.certificates.map((certificate) => certificate.tests),
      );

      const filteredByHeat = transferTests.filter(
        (test) => test.item_heat === heat,
      );

      return tests.concat(filteredByHeat);
    }, []);
  }, [heat]);

  const [dimensionalTests, restTests] = useMemo(
    () =>
      partition(
        (test) => test.type === TYPES.DIMENSIONAL,
        productionTestsFilteredByHeat,
      ),
    [heat],
  );

  const orderedTests = useMemo(
    () => dimensionalTests.concat(restTests),
    [heat],
  );

  const updateFilter = useCallback((filter) => {
    setFilter(filter);
    setOffset(0);
  }, []);

  return (
    <>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <FilterableSortableHeaderCell
              id="productType"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={products}
            >
              Product Type
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="manufacturer"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={products}
            >
              Manufacturer
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="heat"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={products}
            >
              Heat
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="quarantine"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={products}
            >
              Quarantine
            </FilterableSortableHeaderCell>
            <FilterableSortableHeaderCell
              id="quarantineReason"
              setOrder={setOrder}
              order={order}
              setOrderBy={setOrderBy}
              orderBy={orderBy}
              filter={filter}
              setFilter={updateFilter}
              elements={products}
            >
              Quarantine reason
            </FilterableSortableHeaderCell>
            <TableCell>Quantity</TableCell>
            {campaign.type === CAMPAIGN_TYPES.WELDING && (
              <TableCell>Stalk No</TableCell>
            )}
            <TableCell>Production Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoaded ? (
            productsPaginated.map((product) => (
              <TableRow key={product._id}>
                <TableCell>{product.productType || "-"}</TableCell>
                <TableCell>{product.manufacturer}</TableCell>
                <TableCell>{product.heat}</TableCell>
                <TableCell>{product.quarantine}</TableCell>
                <TableCell>{product.quarantineReason || "-"}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                {campaign.type === CAMPAIGN_TYPES.WELDING && (
                  <TableCell>
                    {product.stalkNumbers.join(", ") || "-"}
                  </TableCell>
                )}
                <TableCell>
                  <IconButton onClick={() => setHeat(product.heat)}>
                    <RxOpenInNewWindow />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableSkeleton columns={8} />
          )}
        </TableBody>
        <TableFooter
          isLoaded
          items={productsPaginated}
          total={sorted.length}
          limit={limit}
          offset={offset}
          onOffsetChange={setOffset}
          onLimitChange={setLimit}
        />
      </Table>
      <TestsDrawer
        open={!!heat}
        tests={orderedTests}
        close={() => setHeat(null)}
      />
    </>
  );
});

export default CompactBaseMaterials;
