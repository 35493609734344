import React, {useState} from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Button, Checkbox, FormControlLabel, Popover} from "@mui/material";
import {withStyles} from "tss-react/mui";
import styles from "./styles";

const FiltratedTab = ({onFilter, options, classes}) => {
  const defaultCheckedOptions = options.filter(
    (option) => option.defaultChecked,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(
    defaultCheckedOptions.map((option) => option.value),
  );

  const onCheck = (value) => {
    if (!selectedOptions.includes(value))
      setSelectedOptions([...selectedOptions, value]);
    else
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
  };

  const onClose = () => {
    onFilter(selectedOptions);
    setAnchorEl(null);
  };

  return (
    <>
      <ArrowDropDownIcon
        className={classes.filtrationButton}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        classes={{
          paper: classes.filtrationPopover,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <FormControlLabel
            classes={{root: classes.labelRoot}}
            onClick={() => onCheck(option.value)}
            key={option.title}
            control={
              <Checkbox
                color="primary"
                checked={selectedOptions.includes(option.value)}
              />
            }
            label={option.title}
          />
        ))}
        <div className={classes.buttonContainer}>
          <Button
            size="small"
            onClick={() => setAnchorEl(null)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            color="primary"
          >
            Apply
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default withStyles(FiltratedTab, styles);
