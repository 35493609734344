import React from "react";
import {Box} from "@mui/material";
import {STAGE_CONFIG} from "../../constants";

const InfoBox = ({text}) => {
  return (
    <Box
      sx={{
        fontSize: "1.5rem",
        color: "#a5a5a5",
        backgroundColor: STAGE_CONFIG.BACKGROUND_COLOR,
        position: "absolute",
        width: "100%",
        height: document.fullscreenElement ? "100vh" : "calc(100vh - 155px)",
        display: "flex",
        textAlign: "center",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        zIndex: 2,
      }}
    >
      {text}
    </Box>
  );
};

export default InfoBox;