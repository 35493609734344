import FiltratedTab from "@core/components/TableNavigation/components/FiltratedTab";
import {TABLE_PAGE} from "@core/constants/tablePage";
import {tail} from "ramda";
import React, {useMemo} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {Button} from "@mui/material";
import qs from "qs";

const ButtonLink = ({
  view,
  currentView,
  link,
  titles,
  options,
  onFilter,
  query,
}) => {
  const location = useLocation();
  const search = qs.parse(tail(location.search));

  const currentViewParam = useMemo(
    () => qs.parse(location.search.slice(1)).view,
    [location.search],
  );

  const tabSearch = {...search, [query]: view};
  currentViewParam !== view && delete tabSearch[TABLE_PAGE];

  return (
    <NavLink
      key={view}
      isActive={() => currentView === view}
      to={`${link}?${qs.stringify(tabSearch)}`}
    >
      <Button>{titles ? titles[view] : view}</Button>
      {options[view] && currentView === view && (
        <FiltratedTab options={options[view]} onFilter={onFilter} />
      )}
    </NavLink>
  );
};

export default ButtonLink;
