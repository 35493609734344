import {ORDER_TABS} from "@core/components/TransfersList/data";
import React, {useEffect, useState} from "react";
import {useFetchedCertificateNorms} from "@core/hooks/useFetchedCertificateNorms";
import {observer} from "mobx-react-lite";
import {Formik, FieldArray} from "formik";
import {keys, omit, prop, indexBy, values as getValues} from "ramda";
import {Grid, Typography, Button, MenuItem} from "@mui/material";
import {withStyles} from "tss-react/mui";
import NormAutocomplete from "@core/components/NormAutocomplete";
import {FilesUploader} from "@core/components/Uploaders";
import ProjectIdAutocomplete from "@core/components/ProjectIdAutocomplete";
import ProductTypesAutocomplete from "@core/components/ProductTypeAutocomplete";
import TextField from "@core/components/FormikTextField";
import SelectField from "@core/components/FormikSelect";
import CreateCustomerOrderModal from "@core/components/CreateCustomerOrderModal";
import {getProductTypeFields} from "@core/helpers/getProductTypeFields";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import {getLocationAddress, omitEmptyFields} from "@core/helpers";
import styles from "./styles";
import useStores from "../../../useStores";
import {MuiAutocomplete, MultipleSelect} from "@core/components/Form";
import {TAGS} from "@core/constants/tags";
import {ROUTES} from "@core/api/routes";
import {ACTIONS} from "@core/constants/api";
import {
  initialValues,
  KAR85_SPECIFICATION,
  validationSchema
} from "./constants";

const CertificateForm = ({onSave, classes, user, certificate, formRef}) => {
  const {CertificateStore, TransferStore} = useStores();

  const [properties, setProperties] = useState(
    CertificateStore.product.properties
  );
  const [addCustomerOrderOpen, setAddCustomerOrderOpen] = useState(false);

  const {name, mainLocation = {}, locations = []} = user.company;

  const disabled = certificate && certificate.transactions.length;

  const [order] = TransferStore.transfers.data;

  useEffect(() => {
    if (!certificate?.orderNumber) return;

    TransferStore.transfers.load({
      search: certificate.orderNumber,
      status: ORDER_TABS.OPEN,
      type: INTERFACE_TYPE.SENDER
    });
  }, []);

  const onSubmit = async (values, actions) => {
    const certificate = omitEmptyFields({
      ...values,
      properties: {
        ...values.properties,
        productType: values.properties.productType._id
      }
    });
    await onSave(certificate);
    actions.setSubmitting(false);
  };

  const values = certificate
    ? {...certificate, transferId: order?._id}
    : initialValues;

  return (
    <Formik
      innerRef={formRef}
      initialValues={values}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {(props) => {
        const {values, isValid, handleSubmit, setFieldValue} = props;

        const {norms: allNorms} = useFetchedCertificateNorms();

        const norms = allNorms.filter((n) => n.Norm === values.properties.norm);
        const normsByMaterial = indexBy(prop("Material"), norms) || {};

        const materialGradeChange = (value) => {
          value && setFieldValue("properties.grade", value.Material);
        };

        const normChange = (props) => {
          if (!props?.norm) return;

          setFieldValue("properties.norm", props?.norm ?? "");

          if (!props?.value) {
            setFieldValue("properties.grade", "");

            return;
          }

          if (props?.value.length === 1) {
            const [grade] = props.value;
            materialGradeChange(grade);

            return;
          }

          setFieldValue("properties.grade", "");
        };

        return (
          <>
            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={4}>
                <SelectField
                  name="location"
                  label="Location"
                  required
                  disabled={disabled}
                >
                  <MenuItem key={mainLocation._id} value={mainLocation._id}>
                    {getLocationAddress(mainLocation)}
                  </MenuItem>
                  {locations.map((location) => (
                    <MenuItem key={location._id} value={location._id}>
                      {getLocationAddress(location)}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>

              <Grid item xs={8} container alignItems="center">
                {values.location && (
                  <div className={classes.companyInfo}>
                    <Typography variant="h6">{name}</Typography>
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Heat No."
                  name="heat"
                  disabled={disabled}
                />
              </Grid>

              <Grid item xs={2}>
                <TextField
                  disabled={disabled}
                  label="Lot ID"
                  name="properties.lotId"
                  required={
                    !values.properties.productType.name ||
                    values.properties.productType.name.toLowerCase() !== "heat"
                  }
                />
              </Grid>

              <Grid item xs={2}>
                <SelectField
                  name="certificateType"
                  label="Certificate Type"
                  required
                  disabled={disabled}
                >
                  {["3.1", "3.2"].map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
            </Grid>

            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={3}>
                <NormAutocomplete
                  label="Material Specification"
                  name="properties.norm"
                  testType="certificate"
                  onChange={normChange}
                  required
                  disabled={disabled}
                />
              </Grid>
              {values.properties.norm &&
                values.properties.norm !== KAR85_SPECIFICATION && (
                  <Grid item xs={3}>
                    <SelectField
                      value={props.values.properties.grade}
                      label="Grade / UNS"
                      name="properties.grade"
                      onChange={(value) =>
                        materialGradeChange(normsByMaterial[value])
                      }
                      required
                      disabled={disabled}
                    >
                      {getValues(normsByMaterial).map((item) => (
                        <MenuItem key={item.Material} value={item.Material}>
                          {item.Material}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                )}
              {normsByMaterial[props.values.properties.grade]
                ?.DeliveryConditions && (
                <Grid item xs={3}>
                  <SelectField
                    label="Delivery condition"
                    name="deliveryCondition"
                    disabled={disabled}
                  >
                    {keys(
                      normsByMaterial[props.values.properties.grade]
                        .DeliveryConditions
                    ).map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </SelectField>
                </Grid>
              )}
            </Grid>

            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={2}>
                <TextField
                  disabled={disabled}
                  label="Work order"
                  name="internalWorkOrder"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  disabled={disabled}
                  label="Work order item"
                  name="internalItemNumber"
                />
              </Grid>

              <Grid item xs={2}>
                <MuiAutocomplete
                  disabled={disabled}
                  url={`${ROUTES.TRANSFER[ACTIONS.ALL]}?status=open&type=sender`}
                  inputProps={{
                    label: "Customer order",
                    name: "transferId",
                    required: false
                  }}
                  formatOptions={(data) =>
                    data.items?.map((item) => ({
                      label: item.orderNumber,
                      value: item._id
                    }))
                  }
                  onCreateNew={() => setAddCustomerOrderOpen(true)}
                />
                <CreateCustomerOrderModal
                  onTransferCreated={(transferId) =>
                    setFieldValue("transferId", transferId)
                  }
                  open={addCustomerOrderOpen}
                  setOpen={setAddCustomerOrderOpen}
                  interfaceType={INTERFACE_TYPE.SENDER}
                />
              </Grid>

              {values.transferId && (
                <>
                  <Grid item xs={2}>
                    <TextField
                      disabled={disabled}
                      label="PO Item"
                      name="lineItem"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <ProjectIdAutocomplete
                      disabled={disabled}
                      name="projectId"
                      label="Project ID"
                    />
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container spacing={5} marginBottom={3}>
              <Grid item xs={2}>
                <ProductTypesAutocomplete
                  disabled={disabled}
                  inputProps={{
                    label: "Product type",
                    name: "properties.productType.internalName",
                    required: true
                  }}
                  onChange={(value) => {
                    if (!value) {
                      setFieldValue("properties.productType", "");
                      setFieldValue("items", []);
                    } else {
                      setProperties(value.properties);
                      setFieldValue("properties.productType", value);
                      setFieldValue("items", [
                        {
                          quantity: "",
                          ...getProductTypeFields(value.properties)
                        }
                      ]);
                    }
                  }}
                />
              </Grid>
            </Grid>

            {values.properties.productType && (
              <Typography
                component="h5"
                variant="h5"
                gutterBottom
                style={{marginTop: 16}}
              >
                Items
              </Typography>
            )}

            <FieldArray name="items">
              {({remove}) =>
                values.items?.map((item, index) => (
                  <Grid container spacing={5} key={index} marginBottom={3}>
                    <Grid item xs={2}>
                      <TextField
                        inputProps={{min: 1}}
                        disabled={disabled}
                        name={`items.${index}.quantity`}
                        label="Quantity"
                        required
                        type="number"
                      />
                    </Grid>

                    {keys(omit(["quantity", "_id"], item)).map(
                      (name, itemIndex) => {
                        const property =
                          properties && properties.find((p) => p.name === name);

                        if (!property) return null;

                        return (
                          <Grid key={name + itemIndex} item xs={2}>
                            <TextField
                              disabled={disabled}
                              label={property.label}
                              name={`items.${index}.${name}`}
                              required
                              endAdornment={property.measurements}
                            />
                          </Grid>
                        );
                      }
                    )}

                    {values.items.length > 1 && (
                      <Grid item xs={1} container alignItems="flex-end">
                        <Button
                          disabled={disabled}
                          variant="contained"
                          color="secondary"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                ))
              }
            </FieldArray>

            {values.properties.productType && (
              <Button
                disabled={disabled}
                className={classes.addButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  const items = values.items.concat(
                    getProductTypeFields(properties)
                  );
                  setFieldValue("items", items);
                }}
              >
                Add
              </Button>
            )}

            <Typography
              component="h5"
              variant="h5"
              gutterBottom
              style={{marginTop: 16}}
            >
              Marking
            </Typography>

            <Grid container spacing={3} alignItems="center" marginBottom={3}>
              <Grid item xs={6}>
                <TextField
                  multiline
                  rows={3}
                  label="Marking details"
                  name="markingText"
                />
              </Grid>
            </Grid>

            <Grid container marginBottom={3}>
              <Grid item xs={6}>
                <FilesUploader
                  name="markingFiles"
                  files={values.markingFiles}
                  onNewFile={(file, push) =>
                    push(file.file.dir + file.file.name)
                  }
                  changeFile={(index, file, replace) =>
                    replace(index, file.file.dir + file.file.name)
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} marginBottom={3}>
              <Grid item xs={6}>
                <MultipleSelect
                  disabled={disabled}
                  label="Tags"
                  value={values.tags}
                  elements={TAGS}
                  onChange={(values) => setFieldValue("tags", values)}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} marginBottom={3}>
              <Grid item xs={6}>
                <TextField
                  disabled={disabled}
                  multiline
                  rows={3}
                  label="Additional notes"
                  name="markingNotes"
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="flex-end" marginBottom={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!isValid}
              >
                {certificate ? "Save" : "Create"}
              </Button>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default withStyles(observer(CertificateForm), styles);
