import {TableStore} from "@core/stores";
import axios from "axios";
import {action, makeObservable, observable} from "mobx";
import {isEmpty} from "ramda";
import {ACTIONS} from "@core/constants/api";
import {ROUTES} from "@core/api/routes";
import {ORDERS} from "@core/constants/sort";

class TransferStore {
  constructor() {
    makeObservable(this, {
      transfers: observable,
      producers: observable,
      viewers: observable,
      receivers: observable,
      isLoaded: observable,
      transferInProgress: observable,
      transferDone: observable,
      transferFeedback: observable,
      availablePlots: observable,
      batchTransferFeedback: observable,
      transfer: observable,
      certificate: observable,
      getProducers: action,
      getTransfersReceivers: action,
      transferCertificates: action,
      batchTransfer: action,
      batchPublishCertificate: action,
      removeTransfer: action,
      createTransfer: action,
      getTransfer: action,
      approveDraftTransfer: action,
      reopenTransfer: action,
      getTransferCertificateById: action,
      updateById: action,
    });
  }

  transfers = new TableStore({
    loader: (queryParams) => {
      const newQueryParams = {...this.transfers.fetch, ...queryParams};
      const {offset, limit, search, sort, status, type, excludedIds} =
        newQueryParams;

      const params = {
        sort,
        offset,
        limit,
        search: isEmpty(search) ? null : {value: search},
        status,
        type,
        excludedIds,
      };

      this.transfers.setFetch(newQueryParams);

      return axios.get(ROUTES.TRANSFER[ACTIONS.ALL], {params});
    },
    fetch: {
      sort: {orderBy: "date_created", order: ORDERS.DESC},
    },
  });

  producers = [];

  receivers = [];

  viewers = [];

  isLoaded = false;

  transferInProgress = false;

  transferDone = false;

  transferFeedback = {};

  availablePlots = [];

  batchTransferFeedback = {
    data: {},
    isLoaded: false,
  };

  transfer = {};

  certificate = {
    data: {},
    isLoaded: false,
  };

  async getProducers() {
    this.isLoaded = false;

    const res = await axios.get(ROUTES.USER[ACTIONS.PRODUCERS], {
      withCredentials: true,
    });

    this.producers = res.data;
    this.isLoaded = true;
  }

  // TODO add make sure it's working every where

  async getTransfersReceivers(name) {
    const res = await axios.get(ROUTES.MODULE[ACTIONS.SINGLE_BY_TYPE], {
      params: {name},
    });

    this.receivers = res.data;
  }

  async transferCertificates(data) {
    this.transferInProgress = true;

    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.TRANSFER], data, {
      withCredentials: true,
      successMessage: "Successfully transferred",
    });

    this.transferInProgress = false;
    this.transferFeedback = res.data;
    this.transferDone = true;
  }

  async batchTransfer(data) {
    this.batchTransferFeedback.isLoaded = true;

    const res = await axios.patch(ROUTES.CERTIFICATE[ACTIONS.TRANSFER], data, {
      withCredentials: true,
      data,
    });

    this.batchTransferFeedback = {
      data: res.data,
      isLoaded: false,
    };
  }

  async batchPublishCertificate(data) {
    await axios.post(ROUTES.EXTERNAL_USER[ACTIONS.TRANSFER], data);
  }

  async removeTransfer(id) {
    await axios.delete(ROUTES.TRANSFER[ACTIONS.REMOVE](id));

    this.transfers.data = this.transfers.data.filter((t) => t._id !== id);
    this.transfers.total = this.transfers.total - 1;
  }

  async createTransfer(data) {
    const response = await axios.post(
      ROUTES.DRAFT_TRANSFER[ACTIONS.CREATE],
      data,
    );

    this.transfer = response.data;
    this.transfers.data = [response.data, ...this.transfers.data];
  }

  async getTransfer(id) {
    this.isLoaded = false;
    const response = await axios.get(ROUTES.TRANSFER[ACTIONS.SINGLE](id));

    this.transfer = response.data;
    this.isLoaded = true;
  }

  async approveDraftTransfer(id) {
    await axios.post(ROUTES.TRANSFER[ACTIONS.CLOSE](id));
    this.transfers.data = this.transfers.data.filter((t) => t._id !== id);
  }

  async reopenTransfer(id) {
    await axios.post(ROUTES.TRANSFER[ACTIONS.OPEN](id));
    this.transfers.data = this.transfers.data.filter((t) => t._id !== id);
  }

  async getTransferCertificateById(transferId, certificateId) {
    const response = await axios.get(
      ROUTES.TRANSFER[ACTIONS.SINGLE_CERTIFICATES](transferId, certificateId),
    );

    this.certificate.data = response.data;
    this.certificate.isLoaded = true;
  }

  async updateById(transferId, data) {
    const response = await axios.patch(
      ROUTES.TRANSFER[ACTIONS.UPDATE](transferId),
      data,
    );

    this.transfer = response.data;
  }
}

export default new TransferStore();
