import React, {createRef, Component} from "react";
import lottie from "lottie-web";
import styles from "./styles";
import {withStyles} from "tss-react/mui";

class ErrorBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = {error: false};
    this.message = "";
    this.container = createRef();
  }

  static getDerivedStateFromError() {
    return {error: true};
  }

  loadErrorAnimation() {
    lottie.loadAnimation({
      container: this.container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/animatedImages/error-page.json"
    });
  }

  componentDidCatch(error) {
    // eslint-disable-next-line no-console
    console.log(error);
    this.message = error;

    this.loadErrorAnimation();
  }

  render() {
    const {children, classes} = this.props;
    const {error} = this.state;

    if (error) {
      return (
        <div
          className={classes.container}
          ref={this.container}
        />
      );
    }

    return children;
  }
}

export default withStyles(ErrorBoundary, styles);
