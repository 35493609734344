import React, {useEffect, useState} from "react";
import {difference, intersection, isEmpty} from "ramda";
import {useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {withStyles} from "tss-react/mui";
import referenceService from "@core/api/reference-service";
import WS from "@core/api/socketConnection";
import {FILTERS} from "@core/constants/filters";
import {
  isTestCompleted,
  isCertificateTransferred,
  omitEmptyFields,
  getLocationAddress,
} from "@core/helpers";
import TestsDrawer from "@core/components/TestsDrawer";
import Header from "./Header";
import OrderStatus from "./OrderStatus";
import OrderSummary from "./OrderSummary";
import TestSummary from "./TestSummary";
import Certificates from "./Certificates";
import Campaigns from "./Campaigns";
import CertificatesList from "./CertificatesList";
import PreselectedListModal from "./PreselectedListModal";
import NonConformingTests from "./NonConformingTests";
import CertificateService from "../../../Blockchain/certificates-service";
import {INTERFACE_TYPE} from "@core/constants/transfer";
import styles from "./styles";
import useStores from "../../../useStores";
import useSetInitialViewQueryParam from "@core/hooks/useSetInitialViewQueryParam";
import {DEFAULT_VIEW_TAB} from "@core/components/Transfer/constants";

const Transfer = observer(({classes}) => {
  const [orderNumber, setOrderNumber] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [orderCertificates, setOrderCertificates] = useState([]);
  const [certificatesToTransfer, setCertificatesToTransfer] = useState([]);
  const [showAddCertificates, setShowAddCertificates] = useState(false);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [testsToView, setTestsToView] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    CertificateStore,
    TransferStore,
    UserStore,
    SigningStore,
    NotificationStore,
    FilterStore,
  } = useStores();

  const user = UserStore.user.data;
  const {sender, campaigns = []} = TransferStore.transfer;

  const {id: transferId} = useParams();

  const interfaceType =
    sender && sender._id === user.company._id ?
      INTERFACE_TYPE.SENDER :
      INTERFACE_TYPE.RECEIVER;

  const {filters, setFilters} = FilterStore;

  const selectedHeats = filters[FILTERS.HEAT] || [];
  const selectedLotIds = filters[FILTERS.HEAT_LOT] || [];
  const selectedPoItems = filters[FILTERS.PO_ITEM_NO] || [];
  const selectedLocations = filters[FILTERS.LOCATION] || [];

  const {setInitialViewQueryParam, clearInitialQueryParam} =
    useSetInitialViewQueryParam(DEFAULT_VIEW_TAB, false, true);

  const filteredCertificates = orderCertificates.filter((certificate) => {
    return (
      (!selectedHeats.length || selectedHeats.includes(certificate.heat)) &&
      (!selectedLotIds.length ||
        selectedLotIds.includes(certificate.properties.lotId)) &&
      (!selectedPoItems.length ||
        selectedPoItems.includes(certificate.lineItem)) &&
      (!selectedLocations.length ||
        selectedLocations.includes(getLocationAddress(certificate.location)))
    );
  });

  const filteredCampaigns = campaigns.filter((campaign) => {
    const campaignHeats = campaign.products.map((product) => product.heat);

    return (
      (!selectedHeats.length || intersection(selectedHeats, campaignHeats)) &&
      (!selectedPoItems.length ||
        selectedPoItems.includes(campaign.purchaseOrderItem)) &&
      (!selectedLocations.length ||
        selectedLocations.includes(
          getLocationAddress(campaign.manufacturer.mainLocation),
        ))
    );
  });

  useEffect(() => {
    TransferStore.getProducers();
  }, []);

  useEffect(() => {
    WS.listen("transaction:certificate:batch", async (data) => {
      if (data.status === "ACCEPTED") {
        const receiver = TransferStore.producers.find(
          (r) => r._id === TransferStore.transfer.receiver._id,
        );
        await SigningStore.closeSigner();
        await NotificationStore.showSuccess("Successfully transferred!");
        const certificatesFormatted = certificatesToTransfer.map(
          (certificate) => omitEmptyFields(certificate),
        );
        const data = {
          transferId,
          orderNumber,
          receiver: receiver._id,
          certificates: certificatesFormatted,
        };
        await TransferStore.transferCertificates(data);
        await TransferStore.getTransfer(transferId);
        const {certificates = []} = TransferStore.transfer;
        setShowTransferModal(false);
        setOrderCertificates(certificates);
        setCertificatesToTransfer([]);
      } else {
        NotificationStore.showError("Transaction has been declined");
      }
    });

    return () => {
      WS.remove("transaction:certificate:batch");
    };
  }, [certificatesToTransfer]);

  useEffect(() => {
    TransferStore.getTransfer(transferId).then(() => {
      const {
        certificates = [],
        receiver = {},
        orderNumber = "",
      } = TransferStore.transfer;
      setOrderCertificates(certificates);
      setSelectedCustomer(receiver._id);
      setOrderNumber(orderNumber);

      setIsLoaded(true);
    });

    return () => FilterStore.setFilters({});
  }, [transferId]);

  const batchPublishCertificates = async (data, certificateIds) => {
    await TransferStore.batchPublishCertificate({
      certificates: certificateIds.map((certificateId) => ({
        _id: certificateId,
        lineItem: data[certificateId].lineItem,
        projectId: data[certificateId].projectId,
      })),
      orderNumber,
      viewer: TransferStore.transfer.viewer._id,
    });

    NotificationStore.showSuccess("Successfully published to portal!");
  };

  const handleTransferring = (data, certificateIds) => {
    const receiver = TransferStore.producers.find(
      ({_id}) => _id === selectedCustomer,
    );

    if (!receiver) {
      NotificationStore.showError("Something went wrong!");

      return;
    }

    const transactions = certificateIds.map((certificateId) => {
      const transactionObject = CertificateService.transferCertificate(
        certificateId,
        receiver._id,
      );

      return {
        transactionObject: transactionObject,
        payload: {
          certificateId: certificateId,
          type: "certificate",
          action: "batch",
          sender: user.company,
          receiver,
          orderNumber,
          lineItem: data[certificateId].lineItem,
          projectId: data[certificateId].projectId,
          historyData: {
            receiver: receiver.name,
            orderNumber: orderNumber,
            lineItem: data[certificateId].lineItem,
            projectId: data[certificateId].projectId,
          },
        },
      };
    });

    referenceService
      .create({
        creator: user._id,
        transactions: transactions,
        date_created: new Date(),
      })
      .then((reference) => {
        SigningStore.openSigner(
          {id: reference._id},
          {title: "Batch transfer", createdFor: "the certificates owner"},
        );
      })
      .catch(() => NotificationStore.showError("Something went wrong!"));
  };

  const saveAsDraft = async (certificates) => {
    const newCertificates = difference(
      certificates.map((c) => ({_id: c._id})),
      orderCertificates.map((c) => ({_id: c._id})),
    );
    const removedCertificates = difference(
      orderCertificates.map((c) => ({_id: c._id})),
      certificates.map((c) => ({_id: c._id})),
    );

    if (newCertificates.length) {
      await CertificateStore.addCertificateToOutgoingOrder(
        transferId,
        newCertificates,
      );
    }

    if (removedCertificates.length) {
      await CertificateStore.removeCertificateFromOutgoingOrder(
        transferId,
        removedCertificates,
      );
    }

    await TransferStore.getTransfer(transferId);
    setOrderCertificates(TransferStore.transfer.certificates);
    NotificationStore.showSuccess("Successfully saved!");
  };

  const changeOrderCertificates = async (certificates) => {
    await saveAsDraft(certificates);
  };

  const deselectCertificate = (certificate) => {
    const newSelectedCertificates = orderCertificates.filter(
      (c) => c._id !== certificate._id,
    );
    changeOrderCertificates(newSelectedCertificates);
  };

  const getCertificatesToTransfer = () => {
    if (certificatesToTransfer.length) {
      return orderCertificates.filter((certificate) =>
        certificatesToTransfer.includes(certificate._id),
      );
    }

    const allAvailableToTransfer = orderCertificates.filter((certificate) => {
      const hasTests = certificate.tests.length;
      const isCompleted = certificate.tests.every((test) =>
        isTestCompleted(test),
      );
      const isTransferred = isCertificateTransferred(
        certificate,
        TransferStore.transfer.sender._id,
      );

      return hasTests && isCompleted && !isTransferred;
    });

    return allAvailableToTransfer;
  };

  return (
    <div className={classes.container}>
      <div aria-label="pdf-page-1">
        <Header
          interfaceType={interfaceType}
          setShowTransferModal={setShowTransferModal}
          setOrderCertificates={setOrderCertificates}
          setShowAddCertificates={(showAddCertificates) => {
            setInitialViewQueryParam();
            setShowAddCertificates(showAddCertificates);
          }}
        />
        <OrderStatus
          campaigns={filteredCampaigns}
          certificates={filteredCertificates}
          isLoaded={isLoaded}
        />
        <OrderSummary
          certificates={filteredCertificates}
          filters={filters}
          setFilters={setFilters}
          isLoaded={isLoaded}
        />
        <Campaigns campaigns={filteredCampaigns} />
      </div>
      <NonConformingTests
        certificates={filteredCertificates}
        campaigns={filteredCampaigns}
        setTestsToView={setTestsToView}
      />
      <TestSummary
        certificates={filteredCertificates}
        campaigns={filteredCampaigns}
        setTestsToView={setTestsToView}
        isLoaded={isLoaded}
      />
      <Certificates
        certificatesToTransfer={certificatesToTransfer}
        certificates={filteredCertificates}
        setCertificatesToTransfer={setCertificatesToTransfer}
        setOrderCertificates={setOrderCertificates}
        orderCertificates={orderCertificates}
        deselectCertificate={deselectCertificate}
        interfaceType={interfaceType}
      />
      {interfaceType === INTERFACE_TYPE.SENDER && (
        <>
          <PreselectedListModal
            onSave={
              TransferStore.transfer.receiver ?
                handleTransferring :
                batchPublishCertificates
            }
            open={showTransferModal}
            onClose={() => setShowTransferModal(false)}
            certificates={getCertificatesToTransfer()}
            setCertificatesToTransfer={setCertificatesToTransfer}
          />
          <CertificatesList
            orderCertificates={orderCertificates}
            setOrderCertificates={changeOrderCertificates}
            open={showAddCertificates}
            close={() => {
              clearInitialQueryParam();
              setShowAddCertificates(false);
            }}
          />
        </>
      )}
      <TestsDrawer
        shareLink
        timeline
        open={!isEmpty(testsToView)}
        tests={testsToView}
        close={() => setTestsToView([])}
      />
    </div>
  );
});

export default withStyles(Transfer, styles);
