import React from "react";
import {AppBar, Button} from "@mui/material";
import {withStyles} from "tss-react/mui";
import ButtonLink from "./components/ButtonLink";
import styles from "./styles";

const TableNavigation = ({
  classes,
  onClick,
  views,
  currentView,
  link,
  query,
  titles,
  onFilter,
  options = {},
}) => {
  return (
    <AppBar
      enableColorOnDark
      color="default"
      position="static"
      className={classes.menu}
    >
      {views.map((view) => {
        if (link) {
          return (
            <ButtonLink
              view={view}
              currentView={currentView}
              link={link}
              titles={titles}
              options={options}
              onFilter={onFilter}
              query={query}
            />
          );
        }

        return (
          <Button
            className={view === currentView ? "active" : null}
            onClick={() => onClick(view)}
          >
            {titles ? titles[view] : view}
          </Button>
        );
      })}
    </AppBar>
  );
};

export default withStyles(TableNavigation, styles);
